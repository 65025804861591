import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import InputMultiSelect from "../common/InputMultiSelect";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { APP_MESSAGES, toTitleCase } from "../../utils/constants";
import { postData } from "../../services/http.service";
import { toast } from "react-toastify";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options: any = {
  indexAxis: "y",
  responsive: true,
  maintainAspectRatio: false,
  scaleShowValues: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "District-wise scooters rolled out",
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        padding: 10,
        autoSkip: false,
        fontSize: 10,
      },
    },
    y: {
      stacked: true,
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        padding: 10,
        autoSkip: false,
        fontSize: 10,
      },
      grid: {
        display: false,
      },
    },
  },
};

const PortalAnalyticView = () => {
  const [labels, setBarChartLabels] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [lineChartLabels, setLineChartLabels] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [happyWomenCount, setHappyWomenCount] = useState(null);

  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    let today: any = new Date();
    let currentYear: any = today.getFullYear();
    let years: any = [
      {
        label: currentYear,
        value: currentYear,
      },
    ];
    for (let i = 1; i < 11; i++) {
      let obj: any = {
        label: currentYear - i,
        value: currentYear - i,
      };
      years.push(obj);
    }
    setYearList(years);

    const fetchData = async () => {
      try {
        const response = await postData(
          process.env.REACT_APP_MIS_APPLICATION_COUNT,
          {
            year: "2024",
          }
        );
        if (response && response.data) {
          setHappyWomenCount(response?.data?.totalApprovedCounts);

          let barLabels: any = [];
          let barDataList: any = [];
          response?.data?.districtsWiseCounts.forEach((e: any) => {
            barLabels.push(toTitleCase(e.districtName));
            barDataList.push(e.approvedCount);
          });

          setBarChartLabels(barLabels);
          setBarChartData(barDataList);

          let lineLabels: any = [],
            lineDataList: any = [];
          response?.data?.yearWiseCounts.forEach((e: any) => {
            lineLabels.push(e.month);
            lineDataList.push(e.count);
          });
          setLineChartLabels(lineLabels);
          setLineChartData(lineDataList);
        }
      } catch (error) {
        toast.error(APP_MESSAGES.API_ERR, {
          position: "top-right",
        });
      }
    };

    fetchData();
  }, []);

  const BarChartData = {
    labels: labels,
    datasets: [
      {
        axis: "y",
        label: "Total Scooters Rolled out",
        data: barChartData,
        fill: true,
        backgroundColor: "#ff6402",
        borderWidth: 1,
      },
    ],
  };

  const lineChart = {
    labels: lineChartLabels,
    datasets: [
      {
        label: "Month-wise Scooter Rolled Out",
        data: lineChartData,
        fill: true,
        borderColor: "#FFCD00",
        tension: 0.1,
      },
    ],
  };

  const handleDateChange = async (e: any) => {
    if (e.value) {
      try {
        const response = await postData(
          process.env.REACT_APP_MIS_APPLICATION_COUNT,
          {
            year: e.value,
          }
        );
        if (response && response.data) {
          let barLabels: any = [];
          let barDataList: any = [];
          response?.data?.districtsWiseCounts.forEach((e: any) => {
            barLabels.push(toTitleCase(e.districtName));
            barDataList.push(e.approvedCount);
          });

          setBarChartLabels(barLabels);
          setBarChartData(barDataList);

          let lineLabels: any = [],
            lineDataList: any = [];
          response?.data?.yearWiseCounts.forEach((e: any) => {
            lineLabels.push(e.month);
            lineDataList.push(e.count);
          });
          setLineChartLabels(lineLabels);
          setLineChartData(lineDataList);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <>
      <div style={{ marginTop: "30px", marginBottom: "20px" }} className="container">
        <div className="portal-analytic-main">
          <div>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div className="row">
                  <div className="col-sm-6">
                    <Bar
                      options={options}
                      data={BarChartData}
                      className="protal-chart"
                      width={"400px"}
                      height={"600px"}
                    />
                  </div>
                  <div style={{ height: "400px" }} className="col-sm-6">
                    <InputMultiSelect
                      key={"year"}
                      isMulti={false}
                      name="year"
                      label={t("year")}
                      register={control}
                      options={yearList}
                      errors={errors}
                      onChange={handleDateChange}
                    />
                    <Line data={lineChart} className="protal-chart" height={"200px"} options={{ maintainAspectRatio: false }} />
                    <div className="happy-count">
                      <div id="emoji" className="emoji">
                        {t("HAPPYWOMEN")} : {happyWomenCount}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                ...Profile
              </div>
              <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                ...contact
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortalAnalyticView;
